import { CONSOLE_URL, WEBAPP_URL, WEBSITE_URL, ROOT_DOMAIN } from "@calcom/lib/constants";

// It ensures that redirection URL safe where it is accepted through a query params or other means where user can change it.
export const getSafeRedirectUrl = (url = "") => {
  if (!url) {
    return null;
  }

  //It is important that this fn is given absolute URL because urls that don't start with HTTP can still deceive browser into redirecting to another domain
  if (url.search(/^https?:\/\//) === -1) {
    throw new Error("Pass an absolute URL");
  }

  const urlParsed = new URL(url);

  /**
   * Check if url host minus subdomain matches root domain
   * Fallback to matches CONSOLE_URL, WEBAPP_URL, WEBSITE_URL
   */

  // Avoid open redirection security vulnerability
  if (
    !urlParsed.hostname.endsWith(ROOT_DOMAIN) &&
    ![CONSOLE_URL, WEBAPP_URL, WEBSITE_URL].some((u) => new URL(u).origin === urlParsed.origin)
  ) {
    url = `${WEBAPP_URL}/`;
  }

  return url;
};
